class API {

  constructor() {
        this.API_URL = "https://api.beammeup.nz";
        //this.API_URL = "http://localhost:4000";
    }

    async get(url = '') {
        this.token = await window.localStorage.getItem("token")
        const response = await fetch(this.API_URL + url, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.token
            }
        });
        return response.json(); // parses JSON response into native JavaScript objects
    }

    /* TODO - handle auth rejection (which means the JWT is expired) */
    async post(url = '', data = {}) {
        this.token = await window.localStorage.getItem("token")
        const response = await fetch(this.API_URL + url, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.token
            },
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        });

        if ( response.status != 200 && response.status != 201 ) {
            throw "Server error: Error code is " + response.status
        }

        return response.json(); // parses JSON response into native JavaScript objects
    }

}

const api = new API();
export { api }
