import Vue from 'vue'
import App from './App.vue'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faRunning, faCoffee, faHamburger, faSmileWink, faStar, faHeart, faSmileBeam, faFingerprint,faCheckCircle, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { faMinusSquare } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('font-awesome-icon', FontAwesomeIcon)


library.add(faRunning, faCheckCircle, faFingerprint, faSmileWink, faSmileBeam, faHeart, faStar, faSignOutAlt, faHamburger, faCoffee, faMinusSquare)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
