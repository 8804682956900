<template>
  <div>
      <ul id="filters">
        <img @click="getNewOrder" src="@/assets/starfleet.png" />
        <li><button class="btn btn-info" @click="filter('All')">All</button></li>
        <li><button class="btn btn-info" @click="filter('Bagel')">Bagels</button></li>
        <li><button class="btn btn-info" @click="filter('Coffee')" >Coffee</button></li>
        <li><button class="btn btn-info" @click="filter('Other')">Other</button></li>
        <li><button class="btn btn-info" style="text-align: center;">Change Site</button></li>
      </ul>
      <ul id="old" style="display: block;">
        <p id="currentTime">{{ now }}</p>
        <span id="currentOrdersCount">#{{ filteredOrders.length }}</span>
        <div class="clearfix" style="clear: both"></div>
        <font-awesome-icon @click="getNewOrder" icon="fingerprint" style="font-size: 5em; padding: 10px;"/>
        <font-awesome-icon @click="logout" icon="sign-out-alt" style="font-size: 5em; padding: 10px;" />
        <form @submit.prevent="sendMessage">
        <select style="display: none; margin: 0px auto; margin-top: 10px; width: calc(100% - 20px);" class="form-control" v-model="site">
          <option value="nev">NEV</option>
          <option value="city">City Centre</option>
          <option value="dev">Dev</option>
        </select>
        <OldOrder :id="order.id" :restoreOrder="reviewOldOrder" :order="order" :key="'old-' + order.id" v-for="order in oldOrders"/>
        </form>
      </ul>
      <ul id="active" style="display: grid; margin-top: 20px; margin-bottom: 20px;">
        <Order :categories="siteCategories" :channel="channel" :pos="pos" @onComplete="setComplete" :id="order.id" :order="order" :dbkey="order.dbkey" :key="order.id" v-for="(order, pos) in filteredOrders"/>
      </ul>

    <audio id="newOrderChirp">
      <source src="/chirp.mp3" type="audio/mp3">
    </audio>

    <div v-if="! token " class="modal" tabindex="-1" role="dialog" style="display: block;">
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Log In</h5>
          </div>
          <form @submit="login">
          <div class="modal-body form-group ">
            <p class="input-group-lg"><input class="form-control" type="text" v-model="username" placeholder="E-mail / Username"></p>
            <p class="input-group-lg"><input class="form-control" type="password" v-model="password" placeholder="Password"></p>
            <p class="error">{{ loginError }}</p>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary btn-lg">Log In</button>
          </div>
          </form>
        </div>
      </div>
    </div>

    <div id="siteSelector" v-if="token && stationsAvailable && ! site" class="modal" tabindex="-1" role="dialog" style="display: block;">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Choose Site</h5>
          </div>
          <div class="modal-body form-group">
            <ul>
              <li @click="selectSite(site)" :key="'company-' + company + '-site-' + idx" v-for="(site,idx) in sites">
                {{ site }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div id="stationSelector" v-if="token && site && ! station" class="modal" tabindex="-1" role="dialog" style="display: block;">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Choose Station</h5>
          </div>
          <div class="modal-body form-group">
            <ul>
              <li @click="selectStation(station.name)" :key="'company-' + company + '-site-' + site + '-station-' + idx" v-for="(station, idx) in filteredStations">
                {{ station.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div @click="closeModalFromOutside" v-if="reviewOrder" class="reviewOrder modal" tabindex="-1" role="dialog" style="display: block;">
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <ul>
            <OrderReview :id="reviewOrder.id" :channel="channel" :order="reviewOrder" />
          </ul>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import {Socket} from "phoenix";
import Order from "@/components/Order";
import OldOrder from "@/components/OldOrder";
import OrderReview from "@/components/OrderReview";
import Vue from 'vue';
import { api } from './api';
import dayjs from "dayjs";

const kdsBackendUrl = "wss://api.beammeup.nz/socket";
//const kdsBackendUrl = "ws://localhost:4000/socket";

export default {
  name: 'Dashboard',
  components: {OldOrder, Order, OrderReview},
  data: function () {
    return {
      channel: null,
      socket: null,

      filters: [{ key: 'category', operator: 'in', value: 'All'}],

      message: null,
      currentOrders: [],
      oldOrders: [],

      username: null,
      password: null,
      token: null,
      loginError: null,

      results: null,

      company: null,
      sites: [],
      site: null,
      stations: [],
      filteredStations: [],
      station: null,
      stationsAvailable: false,

      reviewOrder: null,

      time_now: dayjs()
    }
  },
  created() {

    /* check if JSON Web Token exists */
    setInterval(() => { this.time_now = dayjs()}, 1000)

  },
  beforeDestroy() {
    this.socket.disconnect();
  },
  async mounted() {
    this.token = await window.localStorage.getItem("token")
    this.company = window.localStorage.getItem("company")
    this.site = window.localStorage.getItem("site")
    this.station = window.localStorage.getItem("station")
    this.sites = await window.localStorage.getItem("sites")
    this.sites = JSON.parse(this.sites);
    this.stations = await window.localStorage.getItem("stations")
    this.stations = JSON.parse(this.stations);
    this.filterStations();
  },
  watch: {
    'station': {
      handler: function() {
        this.setUpChannel();
      }
    }
  },
  computed: {
    now() {
      return this.time_now.format("HH:mm:ss")
    },
    siteCategories() {
      if ( this.station && this.stations ) {
        var station = this.stations.find(s => { return s.name === this.station })
        return station.categories;
      }
      return [];
    },
    filteredOrders() {
      this.setStationFilter();
      if ( true &&  this.filters && this.filters.length && this.filters[0].value === 'All' ) {
        return this.currentOrders;
      }
      else if ( this.filters && this.filters.length ) {
        var filter = this.filters[0];
        return this.currentOrders.filter(order => {
          return order.data.items.find(item => {
            if ( filter.operator === 'in' ) {
              return filter.value.includes(item[filter.key]);
            }
          });
        });
      }
      else {
        return this.currentOrders
      }
    }
  },
  methods: {
    async selectSite(site) {
      this.site = site;
      await window.localStorage.setItem('site', this.site);
      this.filterStations();
    },
    selectStation(station) {
      this.station = station;
      window.localStorage.setItem('station', this.station);
    },
    setStationFilter() {
      if ( this.stations ) {
        var stationObj = this.stations.find(s => { return s.name === this.station });
        if ( stationObj ) {
          this.filters[0].operator = "in"
          this.filters[0].value = stationObj.categories;
        }
      }
    },
    async getKdsInfo() {
      // this.company = "bagels"
      return api.get('/api/company/get_kds/"' + this.company + '"' ).then(async (data) => {

        /* inject the sites and station data from the returned results */
        /* being a little pedantic with the conditionals here from experience */
        if ( data.rows && data.rows.length && data.rows[0].value && data.rows[0].value.sites && data.rows[0].value.sites.length ) {
          this.sites = data.rows[0].value.sites;
          await window.localStorage.setItem('sites', JSON.stringify(this.sites));
          if ( data.rows && data.rows.length && data.rows[0].value && data.rows[0].value.stations && data.rows[0].value.stations.length ) {
            this.stations = data.rows[0].value.stations;
            await window.localStorage.setItem('stations', JSON.stringify(this.stations));
            this.stationsAvailable = true;
          }
        }
        console.log('KDS Information', data);
      })
      .catch(error => {
        console.log('Error retrieving KDS information', error);
      });
    },
    closeModalFromOutside() {
      this.reviewOrder = null;
    },
    logout() {
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('company');
      window.localStorage.removeItem('site');
      window.localStorage.removeItem('sites');
      window.localStorage.removeItem('station');
      window.localStorage.removeItem('stations');
      this.socket.disconnect();
      this.token = null;
      this.sites = null;
      this.site = null;
      this.stations = null;
      this.station = null;

    },
    async login(event) {
      event.preventDefault();
      let auth = {
        user: this.username || '',
        password: this.password || ''
      };
      this.loginError = null;
      api.post('/api/user/login', auth).then((result) => {
        if ( result === 'user not found' || result === 'unauthorized' ) {
          throw result;
        }
        this.company = result.company;
        this.token = result.jwt_token;
        window.localStorage.setItem("token", this.token);
        window.localStorage.setItem("company", this.company);
      })
      .then(async () => {
        await this.getKdsInfo();
      })
      .catch((error) => {
        this.loginError = error;
      });

    },
    filterStations() {
      if ( this.stations ) {
        this.filteredStations = this.stations.filter((station) => {
          return station.site === this.site
        });
      }
      else {
        this.filteredStations = [];
      }
    },
    reviewOldOrder: function(order) {
      this.reviewOrder = order;
    },
    restoreOrder: function(order) {
      this.oldOrders = this.oldOrders.filter(oldOrder => { return oldOrder != order });
    },
    filter(filterStr) {
      Vue.set(this, 'filters', [{ key: 'category', operation: '==', value: filterStr}]);
    },
    setUpChannel() {

      /* establish the web socket connection */
      this.socket = new Socket(kdsBackendUrl, {params: { guardian_token: this.token, station: this.station }});
      console.log('Attempt to connect to socket with authentication')
      if ( this.token && this.site ) {
        console.log('Ready to connect socket....')
        this.socket.connect();

        /* define the channel */
        if (this.channel) {
          this.channel.leave();
        }

        this.channel = this.socket.channel("kds:" + this.company + ":" + this.site, {})

        /* handle incoming currentOrders on the channel */
        this.channel.on('order:item:completed', payload => {
          if (payload) {

            /* payload = { id: id, pk: pk } */

            var order = this.currentOrders.find(order => {
              return order.id === payload.id
            })
            if (order) {
              var item = order.data.items.find(item => {
                return item.pk === payload.pk
              });
              if (item) {
                item.complete = true;
              }

              /* we only want to complete items for this station */
              let stationItems = order.data.items.filter(item => {
                return this.siteCategories.includes(item.category);
              });

              if (stationItems.every(item => {
                return item.complete === true
              })) {
                order.complete = true;
                this.setComplete(order.id);

              }
            }
          }
        })

        this.channel.on('order:new', payload => {
          payload.rows.forEach(row => {
            this.currentOrders.push({id: row.id, dbkey: row.key, data: row.value});
            const player = document.getElementById("newOrderChirp");
            player.play();
          });
        });

        this.channel.on('order:item:recalled', payload => {
          var id = payload.id;
          // var pk = payload.pk;
          var order = null;

          order = this.currentOrders.find(o => {
            return id === o.id
          })

          /* order doesn't exists - splice it into the currentOrders array and remove it from the oldOrders */
          if ( ! order ) {
            order = this.oldOrders.find(o => {
              return id === o.id
            })
            if ( order ) {
              this.oldOrders = this.oldOrders.filter(o => { return o.id !== id });
              this.currentOrders.splice(0, 0, order);
            }
          }

          var item = order.data.items.find(item => {
            return item.pk === payload.pk;
          })

          if ( item ) {
            item.complete = false;
          }
        });

        /* join the channel */
        this.channel.join()
            .receive("ok", () => {
              /* get new orders for the first time */
              this.getNewOrder()
              console.log('Connected')
            })
            .receive("error", (error) => {
              this.station = null;
              this.socket.disconnect();
              this.stationError = error;
              console.log('Error: ' + JSON.stringify(error))
            });

      }
    },
    getNewOrder() {
      api.get('/api/get_active/' + this.company + '/' + this.site)
          .then(async (data) => {
            this.currentOrders = data.rows.map(row => {
              return {id: row.id, dbkey: row.key, data: row.value}
            });
          })
        .finally(() => {
          console.log('Fetched new orders');
        })

      api.get('/api/get_recent/' + this.company + '/'  + this.site)
          .then(async (data) => {
            this.oldOrders = data.rows.map(row => {
              return {id: row.id, dbkey: row.key, data: row.value}
            });
          })
          .finally(() => {
            console.log('Fetched old orders');
          })


    },
    setComplete(id) {
      const order = this.currentOrders.find(order => { return order.id === id; });
      if ( order ) { this.oldOrders.splice(0,0,order) }

      this.currentOrders = this.currentOrders.filter(order => { return order.id !== id });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

ul#filters {
  display: none;
  position: absolute;
  min-height: 100%;
  background-color: #F5F5DC;
  list-style: none;
  margin: 0px;
  left: 0px;
  top: 0px;
  padding: 20px;
  width: 200px;

  img { margin-left: -20px; margin-top: -20px;}
  li button {
    height: 100%;
    margin-bottom: 10px;
    display: block;
    width: 100%;
    font-size: 2em;
    padding: 1em 0em 1em 0em;
  }

  li:nth-child(1) button { border: none; background-color: orange; }
  li:nth-child(2) button { border: none; background-color: greenyellow; }
  li:nth-child(3) button { border: none; background-color: lightpink; }
}

ul#old {
  position: absolute;
  min-height: 100%;
  background-color: #ddd;
  list-style: none;
  margin: 0px;
  top: 0px;
  left: 0px;
  height: 100%;
  overflow-y: scroll;
  padding: 0px;
  width: 200px;
  z-index: 100;

}

ul#active {
    list-style: none;
    gap: 10px;
    padding-left: 210px;
    grid-template-rows: 100vh;
    grid-auto-flow: column;
    grid-auto-columns: minmax(auto, max-content);
    //height: 100vh;
    overflow-x: scroll;
    margin-right: 10px;
    //overflow-y: scroll;
    li {
	height: fit-content;
	border-radius: 5px;
	border: 1px solid #fff;
    }
    li .item {
    }	
    li:last-child {
	margin-right: 20px;
    }
}

#siteSelector {
  ul { list-style: none;
    margin: 0px; padding: 0px;
    li {
	padding: 10px;
	border-bottom: 2px solid #ccc;
      display: inline-block;
      text-align: center;
      width: 200px;
      border: 1px solid #ccc;
      margin: 10px;
      padding: 10px;
      cursor: pointer;
    }
    li:hover { background-color: #ccc; }
  }
  text-transform: capitalize;
}

#stationSelector {

  .modal-dialog { min-width: 800px; }
  ul { list-style: none;
    margin: 0px; padding: 0px;
    li { padding: 10px; border-bottom: 2px solid #ccc;
      display: inline-block;
      text-align: center;
      width: 210px;
      border: 1px solid #ccc;
      margin: 10px;
      padding: 10px;
      cursor: pointer;
    }
    li:hover { background-color: #ccc; }
  }
  text-transform: capitalize;
}

.reviewOrder ul {
  list-style: none;
  white-space: nowrap;
  margin: 0px;
  height: 800px;
  //width: 400px;
  padding: 0px;
  overflow-y: scroll;
  li:first-child {padding-bottom: 20px;  }
}

.modal {
  background-color: #eeeeee;

  .error {
    text-align: left;
    font-size: 1.2em;
    margin-left: 20px;
    text-transform: capitalize;
    color: red;
  }
}

#currentTime {
  margin: 0px; padding: 0px; font-weight: bold;
  float: left;
  font-size: 1.3em;
  margin-left: 20px;

}
#currentOrdersCount {   font-size: 1.3em; float: right; margin-right: 20px;}
</style>
