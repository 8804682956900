<template>
  <div id="app">
    <Dashboard />
  </div>
</template>

<script>

import Dashboard from './components/Dashboard.vue'

export default {
  name: 'App',
  components: {
    Dashboard
  }
}
</script>

<style>
#app {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;

  font-family: Quicksand, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #97b0c2
}

#app-inner-container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
}


</style>
