<template>
  <span>
    <div @click="ticked = ! ticked" :class="'item' + (item.complete ? ' completed-item' : '')">
    <h1>
      <font-awesome-icon icon="coffee" v-if="order.data.category === 'Coffee'" />
      <font-awesome-icon icon="hamburger" v-if="order.data.category === 'Bagel'" />
      <span style="font-size: 0.7em;" v-if="item.quantity > 1">{{ item.quantity }} x  </span>{{ item.item }}
      <font-awesome-icon icon="check-circle" style="color: red" v-if="ticked" />
    </h1>
    <ul class="list-group">
      <OrderModifier :isBagelType="true" :modifier="getBagelTypeforItem(item)" v-if="getBagelTypeforItem(item)">{{ getBagelTypeforItem(item) }}</OrderModifier>
      <OrderModifier :isBagelType="false" :modifier="modifier" v-bind:key="id + '-' + modifier + '-' + idx + '-' + idy" v-for="(modifier, idy) in item.modifiers.filter(i => { return ! i.match(/^Bagel Type_/) })" />
    </ul>
  </div>
  </span>

</template>


<script>

import OrderModifier from "@/components/OrderModifier";


export default {
  name: 'OrderItem',
  props: ['item', 'order'],
  components: { OrderModifier },
  data: function () {
    return {
      ticked: false,
    }
  },
  methods: {
    getBagelTypeforItem(item) {
      let bagelType = item.modifiers.find(i => { return i.match(/^Bagel Type_/) })
      if ( bagelType ) {
        return bagelType.replace(/^Bagel Type_/, "");
      }
      return false;
    },
  }
}
</script>

<style scoped lang="scss">

.item {
  transition: border-color 5s ease;
  border: none;
  text-align: left;
  border-radius: 15px;
  background-color: white;
  height: min-content;
  padding: 10px;

  &.completed-item {
    //color: red;
    text-decoration: line-through !important;
  }

}


.item:last-child {
  margin-bottom: 0px;
}

.item h1 {
  text-align: center;
  cursor: pointer;
  text-transform: capitalize;
  margin: 0px;
  border-radius: 5px;
  font-size: 1.4em;
  font-weight: 800;
  text-decoration: underline;
}


</style>
