<template>
  <li :class="'order-item-modifier' + (isBagelType ? ' is_bagel_type' : '')">
    <div>
      <h3>
        {{ renderModifier }}
      </h3>
    </div>
  </li>
</template>

<script>

export default {
  name: 'OrderModifier',
  props: ['modifier', 'isBagelType'],
  data: function () {
    return {
    }
  },
  computed: {
    renderModifier: function() {
      return this.modifier.replace(/^.*_/, "");
    }
  }
}
</script>

<style scoped lang="scss">
  .isBagel { background-color: #F5F5DC !important; }

  h3 { font-size: 1.4em; margin: 0px; padding: 0.5em; }

  .is_bagel_type {
    //color: red;
  }

  .order-item-modifier {

    text-align: center;
    //margin-left: 20px;
    cursor: pointer;
    text-transform: capitalize;
    font-size: 1em;

    //list-style-type: disc;

    .remove-item {
      color: red;
      margin-right: 10px;
    }

    .icon {
      float: right;
      font-size: 1.5em;
      margin-top: 0px;
    }
  }
</style>
