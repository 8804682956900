<template>
  <li :class="completed ? 'order-item completed' : 'order-item'" :style="statusColor">
    <div class="details">
      <h1 style="position: relative;">
        <span>{{ order.data.name }}</span>
        <OrderTimer @changeStatus="changeStatus" :created="order.data.created_at" />
      </h1>
      <h1>
        <span class="badge badge-info text-capitalize mr-1">{{ order.data.delivery_option }}</span>
      </h1>

      <hr/>

      <div class='item' v-bind:key="id + '-item-' + idx " v-for="(item, idx) in order.data.items">
        <h1 @click="completeItem($event, item)">
          <font-awesome-icon icon="coffee" v-if="order.data.category === 'Coffee'" />
          <font-awesome-icon icon="hamburger" v-if="order.data.category === 'Bagel'" />
          <span style="font-size: 0.7em;" v-if="item.quantity > 1">{{ item.quantity }} x </span>{{ item.item }}
          <font-awesome-icon icon="check-circle" v-if="item.complete || item.complete === 'True'" />
        </h1>
        <h3 class="bagel-type" v-if="getBagelTypeforItem(item)">{{ getBagelTypeforItem(item) }}</h3>
        <ul class="list-group">
          <OrderModifier :order="order" :modifier="modifier" :idx="idx" :idy="idy" :item="item" v-bind:key="id + '-' + modifier + '-' + idx + '-' + idy" v-for="(modifier, idy) in item.modifiers.filter(i => { return ! i.match(/^Bagel Type_/) })" />
        </ul>
      </div>
    </div>
  </li>
</template>

<script>

import dayjs from 'dayjs';
import OrderTimer from "@/components/OrderTimer";
import OrderModifier from "@/components/OrderModifier";
var customParseFormat = require('dayjs/plugin/customParseFormat')

dayjs.extend(customParseFormat)

export default {
  name: 'Order',
  components: { OrderModifier, OrderTimer },
  props: ['order', 'id', 'onComplete', 'channel'],
  data: function () {
    return {
      now: null,
      grabbed: false,
      completed: false,
      status: 'pending'
    }
  },
  methods: {
    getBagelTypeforItem(item) {
      let bagelType = item.modifiers.find(i => { return i.match(/^Bagel Type_/) })
      if ( bagelType ) {
        return bagelType.replace(/^Bagel Type_/, "");
      }
      return false;
    },
    changeStatus(event) {
      this.status = event.status;
    },
    completeItem(event, item) {
      event.preventDefault();
      event.stopPropagation();
      this.channel.push('order:recall', {
        id: this.order.id,
        pk: item.pk
      });

    },
  },
  computed: {
    statusColor: function() {
      return {'background-color': '#dddddd', 'left': '0px' }
    }
  }
}
</script>

<style scoped lang="scss">
li.order-item {
  position: absolute;
  //width: 250px;
  padding: 10px;
  //margin-left: 10px;
  display: inline-block;
  overflow-y: scroll;
  white-space: normal;
  min-height: 100%;
  height: 100%;
  vertical-align: top;
  transition: background-color 5s ease;
  &.complete { opacity: 0; transition: opacity 4s ease; }

  .item h1 {
    cursor: pointer;
    text-transform: capitalize;
    background-color: #dddddd;
    margin: 10px;
    border-radius: 5px;
    line-height: 2em;
    font-size: 1.8em;
  }

  .item h3.bagel-type {
    cursor: pointer;
    text-transform: capitalize;
    background-color: #F5F5DC;
    margin: 10px;
    border-radius: 5px;
    line-height: 2em;
  }

  .command {
    width: 100%;
    margin: 0px auto;

    .btn { width: calc(100% - 20px); padding: 2em; margin-top: 10px; }
    .btn.grabbed { background-color: orangered; }

    .btn span { background-color: red; display: inline-block; }
    .makeitso { opacity: 0; }
    .makeitso:hover { opacity: 1; transition: opacity 2s; }

    .btn.grabbed .makeitso { position: absolute; left: 20px; border-bottom-left-radius: 5px;  border-top-left-radius: 5px; top: 0px; width: 150px; height: 110.5px; }
  }

  &.completed { position: relative; margin-top: -1000px; transition: margin-top ease-in-out 0.5s; }
}

ul.list-group {
  list-style: none;
}

.player {
  display: none;
}

</style>
